import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { MagicNumbers } from '../../component/app-magic-numbers';
import { ToastrService } from 'ngx-toastr';
import { UtilityService } from '../../services/utility.service';
import { IResponse } from '../../interfaces/response';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from '../../services/common.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ShareButtonsConfig } from 'ngx-sharebuttons';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';



@Component({
  selector: 'app-share-obituary',
  templateUrl: './share-obituary.component.html',
  styleUrls: ['./share-obituary.component.scss']
})
export class ShareObituaryComponent implements OnInit {
  shareConfig: ShareButtonsConfig;
  url1:any;
  descriptions:any;
  image1:any;
  imageMail:any;
  public previewImage: string;
  form: FormGroup;
  isDisabled = true;
  textareaValue;
  public destroy$ = new Subject();
  userData: any;
  titles: string;

  memberIdUrl: string;
  apiUrl: string;
  socialUrl: string;
  url2: any;
  member: any;




  constructor( public http: HttpClient,
    public modalRef: BsModalRef,public loaderService: NgxSpinnerService, public commonService: CommonService,
    private readonly formBuilder: FormBuilder, public toastr: ToastrService, public utilityService: UtilityService) {
    this.form = this.formBuilder.group({
      message: [this.descriptions],
      image: [this.imageMail]
    });
  }

  ngOnInit(): void {
    this.apiUrl = environment.apiUrl;
    this.socialUrl = environment.socialUrl;
    this.previewImage = '';
      if (this.member) {
        const timestamp = Date.now().toString();
        const shortTimestamp = timestamp.substring(timestamp.length - 4);
        this.url2 = (`${this.socialUrl}share-obituary?member_id=${this.member.id}?${shortTimestamp}`);
        this.url1 = (`${this.socialUrl}share-obituary?member_id=${this.member.id}&type=email`);
        
        this.form.patchValue({
          message: `EternallyMe | ${this.member.name}`,
          image: this.member.profile_picture
        });
        this.titles = `EternallyMe | ${this.member.name}`;
        this.imageMail = this.member.profile_picture;
        this.descriptions = `EternallyMe | ${this.member.name} \nRead Obituary at : `;
      }
  }

  updateShareConfig() {
    this.shareConfig = { ...this.shareConfig };
  }

  get message() { return this.form.get('fullName'); }
  get image() { return this.form.get('emailId'); }

  @ViewChild('myTextarea') myTextarea: ElementRef;
  
  onTextareaChange() {
     this.textareaValue = this.myTextarea.nativeElement.value;
    this.descriptions = this.textareaValue;
  }

  fileSelected(event, type?) {
    const fd = new FormData();
    fd.append(event.target.files[0].type.split('/')[0], event.target.files[0]);
    const uploadType = event.target.files[0].type.split('/')[0];
      if (uploadType !== type) {
        this.toastr.error(`Please upload ${type}`);
      } else if (event.target.files[0].size >= MagicNumbers.MbImageSize) {
        this.toastr.error(`Please upload less than 10 mb ${uploadType}`);
      } else {
        this.utilityService['uploadImage'](fd,'other').subscribe((res: IResponse) => {
          if (res && !res.errors) {
            this.imageMail = res.data.url;
            this.toastr.success(res.message);
          }
          event.target.value = '';
        });
      }
  }

  submitForm() {
    this.loaderService.show();
    
   

    if(!this.imageMail){
      this.toastr.error(`Please Add Picture`);
      this.loaderService.hide();
      return;
    }

    if(!this.form.value.message){
      this.toastr.error(`Please Enter Message`);
      this.loaderService.hide();
      return;
    }
    
    this.isDisabled = false;
   
    this.loaderService.hide();
    this.modalRef.hide();
    
  }

}
