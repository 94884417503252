
import { ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { UtilityService } from '../../../../app/common/services/utility.service';
import { CommonService } from '../../../common/services/common.service';
import { MemberService } from 'src/app/common/services/member.service';
import { SearchService } from 'src/app/common/services/search.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { MagicNumbers } from 'src/app/common/component/app-magic-numbers';

@Component({
  selector: 'app-view-itinerary',
  templateUrl: './view-itinerary.component.html',
  styleUrls: ['./view-itinerary.component.scss']
})
export class ViewItineraryComponent implements OnInit{

  form: FormGroup;
  memberList: any;
  memberId: any;
  memberDetails: any;
  itineraryDetails: any;


  constructor(public ref: ChangeDetectorRef, public modalService: BsModalService, public http: HttpClient, public commonStateService: CommonService,  public activatedRoute: ActivatedRoute, public searchService: SearchService,
    public router: Router, public fb: FormBuilder, public utilityService: UtilityService, public inj: Injector, public memberService: MemberService, public loaderService : NgxSpinnerService, public toastr: ToastrService) {
  }


  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      if (params.id) {
        this.memberId = params.id;
        this.getMember();
        this.getItinerary(); 
      }
    });
   
  }

  downloadItinerary(){
    let value =  `${environment.apiUrl}download-itinerary-card/${this.memberId}`;
    window.open(value, '_blank');
  }

  getItinerary(){
      this.loaderService.show();
      this.memberService
        .getItineraryCard(this.memberId)
        .subscribe((res: any) => {
          if (res && !res.errors) {
            this.itineraryDetails = res.data;
          }
          
          this.loaderService.hide();
        },(error)=>{
          this.loaderService.hide();
        });
  }

  getMember() {
    this.loaderService.show();
    this.searchService
      .getViewInfo(this.memberId)
      .subscribe((res: any) => {
        if (res && !res.errors) {
          this.memberDetails = res.data;
        }
        this.loaderService.hide();
      },(error)=>{
      });
  }

  transform(value: string): string {
    if (!value) return '';

    let [hours, minutes, seconds] = value.split(':');
    let period = 'AM';

    let hoursNum = parseInt(hours, MagicNumbers.ten);
    if (hoursNum >= MagicNumbers.twelve) {
      period = 'PM';
      if (hoursNum > MagicNumbers.twelve) hoursNum -= MagicNumbers.twelve;
    } else if (hoursNum === 0) {
      hoursNum = MagicNumbers.twelve;
    }

    return `${hoursNum}:${minutes} ${period}`;
  }

  viewLocationOnMap(latitude: number, longitude: number) {
    const googleMapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
    window.open(googleMapsUrl, '_blank');
  }

  goToMember(){
    this.router.navigate(['/view-information/',this.memberId]);
  }

}
