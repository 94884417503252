import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MagicNumbers } from '../../component/app-magic-numbers';
import { IResponse } from '../../interfaces/response';
import { CommonService } from '../../services/common.service';
import { PaymentService } from '../../services/payment.service';
import { CancelSubscriptionComponent } from '../cancel-subscription/cancel-subscription.component';
import { UpgradePlanComponent } from '../upgrade-plan/upgrade-plan.component';
import { LandingPageService } from '../../services/landing-page.service';

@Component({
  selector: 'app-upgrade-account',
  templateUrl: './upgrade-account.component.html',
  styleUrls: ['./upgrade-account.component.scss']
})
export class UpgradeAccountComponent implements OnInit {
  public billingInfo = false;
  public isPaymentSuccess = false;
  public paymentForm: FormGroup;
  public today = new Date();
  planCancelledOrNotActivated = true;
  memberId?: any;
  cancelledSubs;
  constructor(public formBuilder: FormBuilder, public commonService: CommonService, public modalRef: BsModalRef, public landingPageService: LandingPageService,
    public toastr: ToastrService, public loader: NgxSpinnerService, public paymentService: PaymentService, private readonly modalService: BsModalService) {
    this.paymentForm = this.formBuilder.group({
      cardHolderName: ['', [Validators.required, Validators.maxLength(MagicNumbers.thirty), Validators.pattern('^([a-zA-Z ]+\s)*[a-zA-Z ]+$')]],
      cardExpiry: ['', [Validators.required]],
      cardNumber: ['', [Validators.required, Validators.minLength(MagicNumbers.sixteen), Validators.maxLength(MagicNumbers.nineteen)]],
      cardCvv: ['', [Validators.required, Validators.minLength(MagicNumbers.three), Validators.maxLength(MagicNumbers.four)]],
      cardType: ['Credit', [Validators.required]],
    });
    this.today = new Date(this.today.getFullYear(), this.today.getMonth(), 1);

    const sub = this.cardNumber.valueChanges.subscribe(cardNumber => {
      const formattedCardNumber = this.getFormattedCreditCardNumber(cardNumber);
      if (formattedCardNumber !== cardNumber) {
        this.cardNumber.patchValue(formattedCardNumber);
      }
    });
  }
  get cardType() { return this.paymentForm.get('cardType'); }
  get cardHolderName() { return this.paymentForm.get('cardHolderName'); }
  get cardExpiry() { return this.paymentForm.get('cardExpiry'); }
  get cardCvv() { return this.paymentForm.get('cardCvv'); }
  get cardNumber() { return this.paymentForm.get('cardNumber'); }

  creditCardNumberFormatter(ev: KeyboardEvent) {
    const inputElement = ev.target as HTMLInputElement;
    const value = inputElement.value.trim();
    const formattedNumber = this.getFormattedCreditCardNumber(inputElement.value);
    if (value !== formattedNumber) {
      inputElement.value = formattedNumber;
    }
  }

  private static getCleanedCardNumber(cardNumber: string) {
    return cardNumber?.replace(/\D/g, '') ?? '';
  }

  static getFormattedCreditCardNumber(cardNumber: string) {
    if (!cardNumber) {
      return '';
    }
    const cleanedNumber = this.getCleanedCardNumber(cardNumber);
    const matches = cleanedNumber?.match(this.cardNumberFormatRegex);
    return `${matches?.[MagicNumbers.one] ? matches[MagicNumbers.one] : ''} ${matches?.[MagicNumbers.two] ? (matches[MagicNumbers.two]) : ''} ${matches?.[MagicNumbers.three] ? matches[MagicNumbers.three] : ''} ${matches?.[MagicNumbers.four] ? matches[MagicNumbers.four] : ''}`.trim();
  }

  private static readonly cardNumberFormatRegex = /([0-9]{0,4})?([0-9]{0,4})?([0-9]{0,4})?([0-9]{0,4})?/;


   inputOnlyNumb(ev: KeyboardEvent) {
    const key = ev?.key;
    const allowedKeys = [
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '0',
      'Backspace',
      'Enter',
      'Delete',
      'Tab',
      'ArrowLeft',
      'ArrowUp',
      'ArrowRight',
      'ArrowDown',
      'Home',
      'PageUp',
      'PageDown',
      'End',
      'Insert'
    ];
    return Boolean(key && allowedKeys?.includes(key));
  }

  readonly inputOnlyNumber = this.inputOnlyNumb;

  readonly inputNotNumber = (ev: KeyboardEvent) => {
    const key = ev?.key;
    return !['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(key);
  };

  cardNumberFormatRegex = /([0-9]{0,4})?([0-9]{0,4})?([0-9]{0,4})?([0-9]{0,4})?/;


   getFormattedCreditCardNumber(cardNumber: string) {
    if (!cardNumber) {
      return '';
    }
    const cleanedNumber = this.getCleanedCardNumber(cardNumber);
    const matches = cleanedNumber?.match(this.cardNumberFormatRegex);
    return `${matches?.[MagicNumbers.one] ? matches[MagicNumbers.one] : ''} ${matches?.[MagicNumbers.two] ? (matches[MagicNumbers.two]) : ''} ${matches?.[MagicNumbers.three] ? matches[MagicNumbers.three] : ''} ${matches?.[MagicNumbers.four] ? matches[MagicNumbers.four] : ''}`.trim();
  }

   getCleanedCardNumber(cardNumber: string) {
    return cardNumber?.replace(/\D/g, '') ?? '';
  }

  ngOnInit(): void {
    this.loader.show();

    if (this.modalRef.content.memberId) {
      this.memberId = this.modalRef.content.memberId;
      this.getSubscriptionUpgrade(this.memberId);
      // Now you can use this.memberId in your modal functions
    }
  }
  /**
  * To set the calendar month view 
 */

  getSubscriptionUpgrade(memberId: number) {
    this.landingPageService.getSubscriptionId(memberId).subscribe((res: IResponse) => {
      if (res && !res.errors) {
        this.cancelledSubs = res.data['status'];
        localStorage.setItem('cancelledSubs', JSON.stringify(this.cancelledSubs));
        if (this.cancelledSubs == '0') {
            this.planCancelledOrNotActivated = false;
        }  else {
            this.planCancelledOrNotActivated = true;
        }
      }
    });
  }

  onOpenCalendar(container) {
    container.monthSelectHandler = (event): void => {
      container._store.dispatch(container._actions.select(event.date));
    };
    container.setViewMode('month');
  }
  /**
  * API Call for Submiting the payment details
 */
  submitPayment() {
    const cardNumberFormatted = this.paymentForm.value.cardNumber.replace(/ +/g, '');
    if (this.paymentForm.valid) {
      const payload = {
        card_number: cardNumberFormatted,
        card_type: this.paymentForm.value.cardType,
        cvc: this.paymentForm.value.cardCvv,
        exp_month: 
        new Date(this.paymentForm.value.cardExpiry).getMonth().toString()
          .length === 1
          ? `0${
              Number(new Date(this.paymentForm.value.cardExpiry).getMonth()) +
              1
            }`
          : new Date(this.paymentForm.value.cardExpiry).getMonth(),
        exp_year: new Date(this.paymentForm.value.cardExpiry).getFullYear()
      };
      this.paymentService.upgradePlan(payload).subscribe((res: IResponse) => {
        if (res && !res.errors) {
          this.isPaymentSuccess = true;
          const userData = localStorage.getItem('userData');
          const updatePlanMemberData = JSON.parse(userData);
          updatePlanMemberData.user.get_plan = res.data;
          this.commonService.userData.next(updatePlanMemberData);
          this.commonService.mediaApiCall.next(true);
          localStorage.setItem('userData', JSON.stringify(updatePlanMemberData));
        }
      });

    } else {
      this.paymentForm.markAllAsTouched();
    }
  }


  /**
  * To add the class for expiry date picker
 */
  myFunction() {
    setTimeout(() => {
      document.getElementsByClassName('bs-datepicker')[0].setAttribute('class', 'expiryDate bs-datepicker theme-green');
    }, 0);
  }

  cancelSubs(){
      this.modalRef.hide();
      const initialState = {
        memberId: this.memberId
      };
      this.modalService.show(CancelSubscriptionComponent, { initialState, class: 'custom-modal modal-dialog-centered' });
  }

  upgrade(){
    this.modalRef.hide();
    const initialState = {
      memberId: this.memberId
    };
    this.modalService.show(UpgradePlanComponent, { initialState, class: 'custom-modal modal-dialog-centered' });
}
}
