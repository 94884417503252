import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpRequest, HttpHandler, HttpResponse } from '@angular/common/http';
import { of, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from './common.service';
import { environment } from '../../../environments/environment';
import { MagicNumbers } from '../component/app-magic-numbers';
import { IResponse } from '../interfaces/response';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService {
  public token;
  public destroy$ = new Subject();
  constructor(public loaderService: NgxSpinnerService, public toastr: ToastrService, public commonStateService: CommonService, public router: Router, public authService: AuthService) {
    this.commonStateService.userData.pipe(takeUntil(this.destroy$)).subscribe(res => {
      if (res) {
        this.token = res.token;
      }
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (this.routerUrl(req)) {
      if(!window.location.href.includes("view-information")){
      this.loaderService.show();
      }
    }
    let authorizedRequest;
    const ContentType ='Content-Type';
    const applicationType = req.headers.set(ContentType, 'application/json');
    authorizedRequest = req.clone({
      headers: this.token ? req.headers.set('Authorization', 'Bearer ' + this.token).set(ContentType, 'application/json') : applicationType
    });
    if (this.multipleRequestUrl(req) || req.url.includes('member-edit') || req.url.includes('member-create')) {
      authorizedRequest = req.clone({
        headers: (this.token || this.multipleRequestUrl(req)) ? req.headers.set('Authorization', 'Bearer ' + this.token) : applicationType
      });
    }
    if (req.url.includes('payment_intents') || req.url.includes('sources')) {
      authorizedRequest = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + environment.stripeSecretClient)
          .set(ContentType, 'application/x-www-form-urlencoded')
      });
    }
    return next.handle(authorizedRequest).pipe(
      tap(res => {
        if (res instanceof HttpResponse && res.status === MagicNumbers.twoHundred) {
          return '';
        }
        return res;
      }),
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 403) {
            this.commonStateService.userData.next(null);
                localStorage.clear();
                this.router.navigate(['/landing-page']);
          }
          if (err.error.message !== 'No result(s) found.') {
            this.toastr.error(err.error.message ? err.error.message : 'Something went wrong');
          }
          if (err.status === MagicNumbers.fourHundredOne) {
            this.commonStateService.userData.next(null);
            localStorage.clear();
            this.router.navigate(['/']);
          }
        }
        return of(err);
      }),
      finalize(() => {
        if(this.finalizeRequest(req)){
          if(!window.location.href.includes("view-information")){
            this.loaderService.hide();
          }
        }
      }));
  }

  multipleRequestUrl(req){
    return (req.url.includes('image-upload') || req.url.includes('video-upload'));
  }

  finalizeRequest(req){
   return (!req.url.includes('member-list') || !req.url.includes('add-guest-message') || !req.url.includes('like-comment'));
  }

  routerUrl(req) {
    const isLandingPage = this.router.url.includes('landing-page');
    const isViewInformation = this.router.url.includes('view-information');
    const isStates = req.url.includes('states');
    const isMemberList = req.url.includes('member-list');
    const isLikeComment = window.location.href.includes('like-comment');
  
    return (!isLandingPage && !isViewInformation && !isStates && !isMemberList && !isLikeComment);
  }
  
}
