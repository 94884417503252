<section class="view-info-section padding-zero-view">
    <div class="container res-p-16">
        <div class="first-border">
            <div class="header-img-infinity">
                <img src="assets/images/viewinfo-infinity.svg" alt="viewinfo-infinity">
            </div>
            <div class="header-img-mobile">
                <img src="./../../../../assets/images/header-infinity-brown.svg" alt="header infinity image">
            </div>

            <div class="footer-img-heart">
                <img src="assets/images/viewinfo-heart.svg" alt="viewinfo-heart">
            </div>
            <div class="footer-img-mobile bottom-15">
                <img src="./../../../../assets/images/footer-heart-brown.svg" alt="footer heart image">
            </div>
            <div class="second-border view-all-pic">
                <!-- view all picture memories start here -->
                <section class="picture-memories-section">
                    <div class="memories-section-title">
                        <h2>Picture Memories</h2>
                        <span><a (click)="location.back()" class="link-text">Go Back</a></span>
                    </div>
                    <div class="view-all-wrapper-sec view-all-col">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col">
                                    <div class="main-veiw-all-wrapper">
                                        <mat-list (dndDrop)="onDrop($event, memoriesArr)"
                                            class="dndList gap-1 flex-grow-1 d-flex view-all-wrapper"
                                            [dndDropzone]="['apple', 'banana' ]" dndEffectAllowed="move">
                                            <mat-list-item
                                                class="dndPlaceholder border rounded-1 bg-danger bg-gradient img-video-item"
                                                dndPlaceholderRef>
                                            </mat-list-item>
                                            <mat-list-item
                                                *ngFor="let picture of memoriesArr; let i = index; trackBy: trackByFruit"
                                                [dndDraggable]="picture" (dndStart)="onDragStart( i)"
                                                (dndEnd)="onDragEnd( i, picture, memoriesArr)"
                                                (dndMoved)="onDragged(i, picture, memoriesArr)"
                                                class="border rounded-1 bg-white img-video-item"
                                                dndEffectAllowed="move">
                                                <!-- <span matListItemTitle class=""> -->
                                                <ng-container *ngIf="picture?.type === 0">
                                                    <div class="drag-handle custom-drag-handle">
                                                        <img [src]="picture?.path"
                                                            (click)="goLikeComment(picture); previewImage = picture?.path"
                                                            alt="view-all-img-2" class="view-all-memory-img" />
                                                        <button type="button"
                                                            class="btn btn-link delete-img-btn delete-round-img"
                                                            *ngIf="showSignInGuest && memoriesArr.length > 1"><img
                                                                src="./../../../assets/images/delete-round-new.svg"
                                                                (click)="deleteImage = i" alt="delete-img"></button>
                                                        <button type="button" class="btn btn-link drag-img"><img
                                                                src="./../../../assets/images/drag-round-new.svg"
                                                                alt="drag-img"></button>

                                                    </div>
                                                    <div class="reomove-modal-wrap" *ngIf="deleteImage === i">
                                                        <div class="reomove-modal-sec">
                                                            <div class="reomove-title">Remove Memory?</div>
                                                            <button type="submit"
                                                                class="btn btn-primary-btn fill-button reomove-btn"
                                                                (click)="UpdateMember(picture, i)">Yes, Remove</button>
                                                            <div><a class="cancel-modal-btn"
                                                                    (click)="deleteImage = -1">Cancel</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>

                                                <ng-container *ngIf="picture?.type === 1">
                                                    <div class="drag-handle custom-drag-handle">
                                                        <video (click)="goLikeComment(picture)" alt="view-all-img-2"
                                                            height="175px" *ngIf="picture?.path !== ''" width="25%">
                                                            <source [src]="picture?.path" type="video/mp4"
                                                                class="view-all-memory-img" />
                                                        </video>
                                                        <button type="button"
                                                            class="btn btn-link delete-img-btn delete-round-img"
                                                            *ngIf="showSignInGuest && memoriesArr.length > 1"><img
                                                                src="./../../../assets/images/delete-round-new.svg"
                                                                (click)="deleteVedio = i" alt="delete-img"></button>
                                                        <button type="button" class="btn btn-link drag-img"><img
                                                                src="./../../../assets/images/drag-round-new.svg"
                                                                alt="drag-img"></button>
                                                    </div>
                                                    <div class="reomove-modal-wrap" *ngIf="deleteVedio === i">
                                                        <div class="reomove-modal-sec">
                                                            <div class="reomove-title">Remove Memory?</div>
                                                            <button type="submit"
                                                                class="btn btn-primary-btn fill-button reomove-btn"
                                                                (click)="UpdateMember(picture, i)">Yes, Remove</button>
                                                            <div><a class="cancel-modal-btn"
                                                                    (click)="deleteVedio = -1">Cancel</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="video-icon" *ngIf="picture?.path !== ''">
                                                        <img src="./../../../assets/images/video-img.svg"
                                                            alt="eternallyme video image"
                                                            (click)="showVideo(picture?.path)" class="video-icon-img" />
                                                    </div>
                                                </ng-container>
                                            </mat-list-item>
                                        </mat-list>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</section>

<div class="modal image-preview-modal fade" bsModal #staticModal="bs-modal" [config]="{backdrop: 'static'}"
    tabindex="-1" role="dialog" aria-labelledby="dialog-static-name">
    <div class="modal-dialog modal-sm image-preview-modal-dialog">
        <div class="modal-content image-preview-modal-content">
            <div class="modal-body">
                <div class="image-preview-wrapper">
                    <img [src]="previewImage" alt="image-preview" class="image-preview img-fluid" />
                </div>
                <button type="button" class="btn btn-link image-preview-close-btn" (click)="staticModal.hide()"><img
                        src="assets/images/image-preview-close.svg" alt="close-btn" class="img-fluid" /></button>
            </div>
        </div>
    </div>
</div>
