import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { IResponse, StripeResponse } from '../../../../app/common/interfaces/response';
import { MagicNumbers } from '../../../../app/common/component/app-magic-numbers';
import { PaymentService } from '../../../../app/common/services/payment.service';
import { UtilityService } from '../../../../app/common/services/utility.service';
import { MemoryCodeRequiredComponent } from '../memory-code-required/memory-code-required.component';
import { SigninComponent } from '../signin/signin.component';
import { environment } from '../../../../environments/environment';
import { CommonService } from '../../../common/services/common.service';
import { Stripe, StripeElements, loadStripe } from '@stripe/stripe-js';
import { MemberService } from 'src/app/common/services/member.service';

@Component({
  selector: 'app-payment-purchase-memory-code',
  templateUrl: './payment-purchase-memory-code.component.html',
  styleUrls: ['./payment-purchase-memory-code.component.scss']
})
export class PaymentPurchaseMemoryCodeComponent implements OnInit,OnDestroy{
  stripe: Stripe;
  stripeElements: StripeElements;
  public detailsForm: FormGroup;
  public paymentForm: FormGroup;
  public today = new Date();
  public isDetailShow = true;
  public selectedCity = false;
  public selectedState = false;
  public isPaymentSuccess = false;
  public states;
  public cities;
  public basicDetails;
  public paymentCreatedDetails;
  public bodyElement;
  public submitted = false;
  public disablePaymentBtn = false;
  public cardNumber;
  public cardExpiry;
  public cardCvv;
  public hideCardNumber;
  public cardNumberMSG;
  public expiryMSG;
  public hideCardExpiry;
  public cvvMSG;
  public hideCardCvv;
  public showCountryCode = false;
  public showNav = false;
  public paymentService: PaymentService;
  public loader: NgxSpinnerService;
  public datePipe: DatePipe;
  public toastr: ToastrService;
  public payAmount;
  public profilePic: string = '';
  elementStyles: {
    base: {
      '::placeholder': {
        color: '#cfd7df',
      },
      ':-webkit-autofill': {
        color: '#e39f48',
      },

    },
    invalid: {
      color: '#E25950',

      '::placeholder': {
        color: '#FFCCA5',
      },
    },
  };planType: any;
  tokenResponse: any;
  userData: any;
  cardToken: string;
;
  elementClasses: {
    focus: 'focused',
    empty: 'empty',
    invalid: 'invalid',
  };
  constructor(public ref: ChangeDetectorRef, public modalService: BsModalService, public http: HttpClient, public commonStateService: CommonService, public memberService: MemberService,
    public router: Router, public formBuilder: FormBuilder, public utilityService: UtilityService, public inj: Injector) {
    this.paymentService = inj.get<PaymentService>(PaymentService);
    this.loader = inj.get<NgxSpinnerService>(NgxSpinnerService);
    this.datePipe = inj.get<DatePipe>(DatePipe);
    this.toastr = inj.get<ToastrService>(ToastrService);

    this.detailsForm = this.formBuilder.group({
      profileImage: ['', [Validators.required]],
      fullName: ['', [Validators.required, Validators.maxLength(MagicNumbers.thirty)]],
      contactNumber: ['', [Validators.required, Validators.minLength(MagicNumbers.ten), Validators.maxLength(MagicNumbers.ten)]],
      emailId: ['', [Validators.required,Validators.email,Validators.maxLength(MagicNumbers.fifty)]],
      deliverAddress: ['', [Validators.required, Validators.maxLength(MagicNumbers.oneHundred)]],
      zipCode: ['', [Validators.required, Validators.maxLength(MagicNumbers.ten), Validators.minLength(MagicNumbers.five)]],
      state: ['', [Validators.required]],
      city: ['', [Validators.required]],
      plan_type : [''],
      deliver_code_type : [''],
      serial_number : ['']
    });
    this.paymentForm = this.formBuilder.group({
      cardHolderName: ['', [Validators.required, Validators.maxLength(MagicNumbers.fifty), Validators.pattern('^([a-zA-Z ]+\s)*[a-zA-Z ]+$')]],
      cardType: ['Credit', [Validators.required]],
    });
    ref.detach();
    setInterval(() => {
      this.ref.detectChanges();
    }, MagicNumbers.oneHundred);
    const memory = JSON.parse(localStorage.getItem('memoryStatus'));
    this.detailsForm.patchValue(
      {
        plan_type : memory?.planType,
        deliver_code_type : memory?.code,
        serial_number : memory?.memoryCode
      }
    );
    this.planType = memory?.planType;
    loadStripe(environment.stripePublicClient).then(res => {
      this.stripe = res;
      this.stripeElements = res.elements();
    });
  }

  ngOnInit(): void {
    this.getStates();
    // Add class on body
    this.bodyElement = document.body;
    this.bodyElement.classList.add('remove-padding');

    const userData = localStorage.getItem('userData');
    const updatePlanMemberData = JSON.parse(userData);
    this.payAmount = updatePlanMemberData.basic_plan_amount;

      this.memberService.checkUserDetails(updatePlanMemberData.user.id).subscribe((res: any) => {
        if (res) {
          this.userData = res;
          this.detailsForm.patchValue({
            fullName: this.userData.data.full_name,
            emailId: this.userData.data.email,
            contactNumber: this.userData.data.contact_number,
            deliverAddress: this.userData.data.address,
            zipCode: this.userData.data.zip_code,
            state: this.userData.data.state_id,
            city: this.userData.data.city_id
          });
          if(this.userData.data.profile_picture){
            this.profilePic = this.userData.data.profile_picture;
            this.detailsForm.controls.profileImage.setValue(this.profilePic);
          }
        }
      });
  }

  get fullName() { return this.detailsForm.get('fullName'); }
  get profileImage() { return this.detailsForm.get('profileImage'); }
  get contactNumber() { return this.detailsForm.get('contactNumber'); }
  get emailId() { return this.detailsForm.get('emailId'); }
  get deliverAddress() { return this.detailsForm.get('deliverAddress'); }
  get zipCode() { return this.detailsForm.get('zipCode'); }
  get state() { return this.detailsForm.get('state'); }
  get city() { return this.detailsForm.get('city'); }
  get cardType() { return this.paymentForm.get('cardType'); }
  get cardHolderName() { return this.paymentForm.get('cardHolderName'); }

  /**
   * API Call for states List
  */
  // getStates() {
  //   this.utilityService.getStates().subscribe((res: IResponse) => {
  //     if (res && !res.errors) {
  //       this.states = res.data;
  //       setTimeout(() => {
  //         this.detailsForm.controls.state.setValue(this.userData.data.state_id || null);
  //         // this.selectedState = false;
  //         this.selectedState = !!this.userData.data.state_id;
  //       }, MagicNumbers.oneHundred);
  //     }
  //   });
  // }

  // getCity(event) {
  //   // console.log(event)
  //   this.cities = [];
  //   this.utilityService.getCities(event?.target?.value).subscribe((res: IResponse) => {
  //     if (res && !res.errors) {
  //       setTimeout(() => {
  //         // console.log(res)
  //         this.detailsForm.controls.city.setValue(this.userData.data.city_id || null);
  //         this.selectedCity = false;
  //         // this.selectedCity = !!this.userData.data.city_id;

  //       }, MagicNumbers.oneHundred);
  //       this.cities = res.data;
  //     }
  //   });
  // }

  getStates(): void {
    this.utilityService.getStates().subscribe((res: IResponse) => {
      if (res && !res.errors) {
        this.states = res.data;
        setTimeout(() => {
          this.detailsForm.controls.state.setValue(this.userData.data.state_id || null);
          this.selectedState = !!this.userData.data.state_id;
          if (this.userData.data.state_id) {
            this.getCity({ target: { value: this.userData.data.state_id } });
          }
        }, 100); // MagicNumbers.oneHundred
      }
    });
  }

  getCity(event: any): void {
    this.cities = [];
    this.utilityService.getCities(event.target.value).subscribe((res: IResponse) => {
      if (res && !res.errors) {
        this.cities = res.data;
        setTimeout(() => {
          this.detailsForm.controls.city.setValue(this.userData.data.city_id || null);
          this.selectedCity = !!this.userData.data.city_id;
        }, 100); // MagicNumbers.oneHundred
      }
    });
  }
  /**
   * To open the signin modal
  */
  openSignIn() {
    this.modalService.show(SigninComponent, { class: 'signin-modal' });
  }
  /**
   * To open the qr memory code modal
  */
  openMemoryCode() {
    this.modalService.show(MemoryCodeRequiredComponent, { class: 'signin-modal' });
  }
  /**
     * API Call for cities list based on state id
     * @param event is required
    */
 

  /**
   * Taking the user card details for payment
  */
  submitDetails() {
    if (this.detailsForm.valid) {
      this.loader.show();
      const data = {
        full_name: this.detailsForm.value.fullName,
        address: this.detailsForm.value.deliverAddress,
        city_id: this.detailsForm.value.city,
        state_id: this.detailsForm.value.state,
        zip_code: this.detailsForm.value.zipCode
      };

      this.memberService.validateShippoAddress(data).subscribe((res: IResponse) => {
        if (res && !res.errors) {
          this.loader.hide();
          this.isDetailShow = false;
          this.basicDetails = this.detailsForm.value;
          if (!this.isDetailShow) {
          this.createCardNumber();
          this.createCardExpiry();
          this.createCVV();
          }
        } else {
          this.toastr.error(res.message);
          this.loader.hide();
        }
      }, error => {
        this.loader.hide();
        this.toastr.error(error.message);
      });
    } else {
      this.detailsForm.markAllAsTouched();
    }
  }

  //For creating card cvc block
  createCVV(){
    this.cardCvv = this.stripeElements.create('cardCvc', {
      style: this.elementStyles,
      classes: this.elementClasses,
      placeholder: ''
    });
    this.cardCvv.mount('#cardCvv-element');
    //Listening the card cvv
    this.cardCvv.addEventListener('change', (event) => {
      if (event.error) {
        this.cvvMSG = event.error.message;
      } else {
        if (event.empty === false) {
          this.hideCardCvv = true;
        } else {
          this.hideCardCvv = false;
        }
        this.cvvMSG = '';
      }
    });
  }

  //for creating card expiry block
  createCardExpiry(){
    this.cardExpiry = this.stripeElements.create('cardExpiry', {
      style: this.elementStyles,
      classes: this.elementClasses,
      placeholder: ''
    });
    this.cardExpiry.mount('#cardExpiry-element');
    //Listening the card expiry
    this.cardExpiry.addEventListener('change', (event) => {
      if (event.error) {
        this.expiryMSG = event.error.message;
      } else {
        if (event.empty === false) {
          this.hideCardExpiry = true;
        } else {
          this.hideCardExpiry = false;
        }
        this.expiryMSG = '';
      }
    });
  }
  //For creating card Number
  createCardNumber(){
    this.cardNumber = this.stripeElements.create('cardNumber', {
      style: this.elementStyles,
      classes: this.elementClasses,
      placeholder: ''
    });
    this.cardNumber.mount('#cardNumber-element');
    //Listening the card number when it changes
    this.cardNumber.addEventListener('change', (event) => {
      // callRealAddEve
      if (event.error) {
        this.cardNumberMSG = event.error.message;
      } else {
        if (event.empty === false) {
          this.hideCardNumber = true;
        } else {
          this.hideCardNumber = false;
        }
        this.cardNumberMSG = '';
      }
    });
  }
  /**
   * API Call for submiting the payment details
  */

  uploadProfilePic(event, type?) {
    const fd = new FormData();
    const checkType = type ? event[0].type.split('/')[0] : event.target.files[0].type.split('/')[0];
    const checkSize = type ? event[0] : event.target.files[0];
    fd.append('image', type ? event[0] : event.target.files[0]);
    if (checkType !== 'image') {
      this.toastr.error('Please upload image');
    } else if (checkSize <= MagicNumbers.MbSize) {
      this.toastr.error(`Please upload less than 10 mb image`);
    } else {
      this.utilityService.uploadImage(fd, 'profile_image').subscribe((res: IResponse) => {
        if (res && !res.errors) {
          this.toastr.success('Profile picture uploaded successfully.');
          this.profilePic = res.data.url;
          this.basicDetails.profileImage.value = this.profilePic;
        }
      });
    }
  }


  submitPayment() {
    this.submitted = true;
    if (this.validationCheck()) {
      this.loader.show();
      this.stripe.createToken(this.cardNumber).then(res => {
        this.stripe.createPaymentMethod(
          {
            type: 'card',
            card: this.cardNumber,
            billing_details: {
                address: {
                  city: this.basicDetails.city,
                  country: 'US',
                  line1: this.basicDetails.deliverAddress,
                  postal_code: this.basicDetails.zipCode,
                  state: this.basicDetails.state,
                },
                name: this.paymentForm.value.cardHolderName,
                email: this.basicDetails.emailId,
                phone: this.basicDetails.contactNumber
            },
        }
        ).then(async (result) => {
          if (result.error) {
            this.loader.hide();
            this.toastr.error(result.error.message);
          } else {
            this.createPaymentIntent(result);
            this.cardToken = res.token?.id;
            // this.paymentService.createCardFromToken(res.token?.id).subscribe((res: IResponse) => {
            //   if (res && !res.errors) {
            //   }else {
            //     this.loader.hide();
            //   }
            // });
          }
        });
      });
    } else {
      this.paymentForm.markAllAsTouched();
    }
  }
  
  validationCheck(){
   return (this.paymentForm.valid && this.cardNumber._complete && this.cardExpiry._complete && this.cardCvv._complete);
  }
 /**
   * API Call for create Payment Intent
  */
  createPaymentIntent(result){
    this.utilityService.createPaymentIntent(
      { amount: (this.payAmount * MagicNumbers.oneHundred), currency: 'usd', id: result.paymentMethod.id }
    ).subscribe((res1: StripeResponse) => {
      if (res1.status === 'requires_confirmation' || res1.status === 'requires_payment_method') {
        this.paymentCreatedDetails = res1;
        this.confirmCardPayment(res1);
      } else {
        this.loader.hide();
        this.toastr.error(res1.error.message);
      }
    });
  }
 /**
   * API Call for confirm Card Payment
  */
  confirmCardPayment(res1){
    this.stripe.confirmCardPayment(res1.client_secret)
    .then((result1) => {
      this.loader.hide();
      if (result1.error) {
        this.toastr.error(result1.error.message);
      } else {
        this.disablePaymentBtn = true;
        this.callAPIForStripe(result1);
      }
    }).catch((err) => {
      this.toastr.error('Payment failed please try again');
    });
  }
/**
   * generate payloads Card Payment
  */
  generatePayloads(){
   return {
      type: 'card',
      card: this.cardNumber,
      billing_details: {
        address: {
          city: this.basicDetails.city,
          country: 'US',
          line1: this.basicDetails.deliverAddress,
          postal_code: this.basicDetails.zipCode,
          state: this.basicDetails.state,
        },
        name: this.paymentForm.value.cardHolderName,
        email: this.basicDetails.emailId,
        phone: this.basicDetails.contactNumber
      },
    };
  }
  /**
   * API Call for storing the payment details
  */
  callAPIForStripe(id) {
    if(this.profilePic){
      this.detailsForm.value.profileImage = this.profilePic;
    }
    const data = {
      profile_picture: this.detailsForm.value.profileImage,
      full_name: this.basicDetails.fullName,
      email: this.basicDetails.emailId,
      contact_number: this.basicDetails.contactNumber,
      address: this.basicDetails.deliverAddress,
      zip_code: this.basicDetails.zipCode,
      state_id: this.basicDetails.state,
      city_id: this.basicDetails.city,
      amount: this.payAmount,
      transaction_key: this.paymentCreatedDetails.id,
      status: 1,
      plan_type:this.detailsForm.value.plan_type,
      deliver_code_type : this.detailsForm.value.deliver_code_type,
      tran_created_at: decodeURIComponent(this.datePipe.transform(new Date(), 'yyyy-MM-dd hh:mm:ss') || ''),
      card_type : this.paymentForm.value.cardType === 'Credit' ? 1 : MagicNumbers.two,
      card_token: this.cardToken
    };
    const serial_number = this.detailsForm.value.serial_number ? `&serial_number=${this.detailsForm.value.serial_number}` : '';
    this.paymentService.createStripeCustomer(data,this.detailsForm.value.serial_number,serial_number).subscribe((res: IResponse) => {
      if (res && !res.errors) {
        this.isDetailShow = true;
        this.isPaymentSuccess = true;
        const userData = localStorage.getItem('userData');
          const updatePicture = JSON.parse(userData);
          updatePicture.user.profile_picture = this.profilePic;
          this.commonStateService.userData.next(updatePicture);
          localStorage.setItem('userData', JSON.stringify(updatePicture));
      }
    });
  }

  /**
   * To navigate to previous step
  */
  goBack() {
    this.cardNumber.destroy();
    this.cardCvv.destroy();
    this.cardExpiry.destroy();
    this.isDetailShow = true;
  }
  /**
   * To toggle nav bar in mobile
  */
  navToggle() {
    this.showNav = !this.showNav;
  }
  /**
   * To navigate about us page
  */
  goToAboutUs() {
    this.showNav = false;
    this.router.navigate(['/about-us']);
  }
  ngOnDestroy() {
    this.bodyElement.classList.remove('remove-padding');
    if (this.isPaymentSuccess || !this.isDetailShow) {
      this.cardNumber.destroy();
      this.cardCvv.destroy();
      this.cardExpiry.destroy();
    }
  }

  getValue(value){
    this.planType = value;
    this.detailsForm.patchValue(
      {
        plan_type : value,
      }
    );
    localStorage.setItem('planType',value);
      const userData = localStorage.getItem('userData');
          const updatePlanMemberData = JSON.parse(userData);
          updatePlanMemberData.user.plan_type = value;
          this.commonStateService.userData.next(updatePlanMemberData);
          localStorage.setItem('userData', JSON.stringify(updatePlanMemberData));
  }
  inviteCoOwners(){
    const userInfo = JSON.parse(localStorage.getItem("userData"));
    userInfo.user.subscription_status =  JSON.parse(localStorage.getItem('memoryStatus')).planType;
    localStorage.setItem('userData',JSON.stringify(userInfo));
    this.router.navigate(['/invite-co-owner']);
  }
}
