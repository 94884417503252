import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../../services/common.service';
import { ToastrService } from 'ngx-toastr';
import { UtilityService } from '../../services/utility.service';
import { AddGuestbookService } from '../../services/add-guestbook.service';
import { SearchService } from '../../services/search.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { NgxSpinnerService } from 'ngx-spinner';
import { DndDropEvent } from 'ngx-drag-drop';


type Apple = 'apple';
type Banana = 'banana';
type FruitType = Apple | Banana;

interface Fruit {
  id: number;
  type: FruitType;
}

let id = 0;

function createFruit(type: FruitType) {
  return {
    id: id++,
    type: type,
  };
}

function range(start: number, end: number) {
  return Array.from({ length: end - start + 1 }, (_, i) => i);
}

@Component({
  selector: 'app-profile-pictures',
  templateUrl: './profile-pictures.component.html',
  styleUrls: ['./profile-pictures.component.scss']
})
export class ProfilePicturesComponent {
  public memoriesArr: any[] = [];
  public previewImage: string;
  public deleteImage:any;
  public deleteVedio:any;
  public memoriesData :any;
  public guestbookMessageList: any = [];
  public videosArr: string[] = [];
  memberId: any;
  showSignInGuest: any;
  showSignInMember: any;
  getPicture: any[]=[];
  viewInfoUrl: any;
  viewInfoId:any;
  remove;
  constructor(
    public activateRoute: ActivatedRoute,
    public location: Location,
    public addGuestbookService: AddGuestbookService,
    public utilityService: UtilityService,
    public commonService: CommonService,
    public toastr: ToastrService,
    public router: Router,
    public searchService: SearchService,
    public loaderService: NgxSpinnerService

    ) {
    this.previewImage = '';
    // To get the profile memories
    this.activateRoute.queryParams.subscribe(queryParams => {
      if (queryParams.memories || queryParams.showSignInGuest || queryParams.id || queryParams.showSignInMember) {
        this.viewInfoUrl = JSON.parse(queryParams?.viewUrl);
        this.viewInfoId = JSON.parse(queryParams?.id);
        this.memberId = JSON.parse(queryParams?.id);
        this.showSignInGuest = JSON.parse(queryParams?.showSignInGuest);
        this.showSignInMember = JSON.parse(queryParams.showSignInMember);
      }
     const userData = JSON.parse(localStorage.getItem('userData'));
     if(userData){
       this.checkPendingReq(userData?.user.member?.id);
     }
    });
    this.getViewInfo();
  }

    /**
   * API Call for View member information
   */
    getViewInfo() {
      this.searchService
        .getViewInfo(this.viewInfoId)
        .subscribe((res: any) => {
          if (res && !res.errors) {
            this.memoriesArr = res.data.member_gallery;
            this.getPicture = res.data.member_gallery.filter(res=> res.type===0);
          }
        });
    }
  /**
   * show Icon red method
  */
  checkPendingReq(memberId){
    this.utilityService.checkPendingReq(memberId).subscribe((res: any) => {
      if (res && !res.errors) {
         this.commonService.guestRequestSharing.next(res.data);
      }
      });
  }
/**
* API Call to update Member Information
*/
UpdateMember(picture,index){
  const payloads:any = {
    id: picture.id,
  };
  this.memoriesArr.splice(index,1);
  this.deleteImage = -1;
  this.deleteVedio= -1;
  this.addGuestbookService.updateMemberInformation(payloads).subscribe((res: any) => {
    if (res) {
      this.toastr.success(res.message);
      this.getViewInfo();
    }
  });
}

  showVideo(video: string) {
    window.open(video, '_blank');
  }
/**
* API Call to go like screen
*/
  goLikeComment(picture:any) {
      this.router.navigate(['/like-comment'],
      {
        queryParams: {
          id: this.memberId,
          pictureId : picture.id,
          showSignInGuest : this.showSignInGuest,
          showSignInMember : this.showSignInMember
        },
      });
  }


  updateOrderOnServer(memoriesArr) {
    let orderData = memoriesArr.map((item, index) => ({
      id: item.id,
      order: index
    }));
  
    // Use a Set to track seen IDs and filter out duplicates (keep the first occurrence)
    const seenIds = new Set();
    orderData = orderData.filter(item => {
      if (seenIds.has(item.id)) {
        return false; // If the ID is already seen, remove the item
      }
      seenIds.add(item.id);
      return true; // Keep the item if the ID hasn't been seen yet
    });
    this.loaderService.show();
    this.addGuestbookService.dragAndDropGuest({ order_data: orderData }).subscribe(response => {
      if (response) {
        this.loaderService.hide();
      } else {
        this.loaderService.hide();
      }
    });
  }
  // public fruits: Fruit[] = range(0, 100).map(_ => {
  //   const randomFruitType: FruitType = Math.random() < 0.5 ? 'apple' : 'banana';
  //   return createFruit(randomFruitType);
  // });


  trackByFruit(index: number, fruit: Fruit) {
    return fruit;
  }

  
  // newIndex;

  onDragStart(index:number) {
    this.remove  = index;
  }

    
  onDragEnd(index:number,fruit: Fruit, memoriesArr) {
  }

  onDragged(index: number, fruit: Fruit, memoriesArr) {
  }

  onDrop(event: DndDropEvent, memoriesArr: any[]) {   
      const draggedItem = event.data;
  
    // Find and remove the dragged item from its previous position
    // const removeIndex = memoriesArr.indexOf(draggedItem);
      memoriesArr.splice(this.remove, 1);
    // }
    // Determine the new index to insert the item
    let newIndex = event.index;
    if (typeof newIndex === 'undefined') {
      newIndex = memoriesArr.length;
    }

    if(event.index > this.remove){
  memoriesArr.splice(newIndex-1, 0, draggedItem);  
} else{
  memoriesArr.splice(newIndex, 0, draggedItem);  
}
    this.updateOrderOnServer(memoriesArr);
  }

}
