import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from '../../../environments/environment';
import { MagicNumbers } from '../../common/component/app-magic-numbers';
import { CommonService } from '../../common/services/common.service';
import { IResponse } from 'src/app/common/interfaces/response';
import { MemberService } from 'src/app/common/services/member.service';
import { ToastrService } from 'ngx-toastr';
import { concatMap } from 'rxjs/operators';

@Component({
  selector: 'app-about-memorial',
  templateUrl: './about-memorial.component.html',
  styleUrls: ['./about-memorial.component.scss']
})
export class AboutMemorialComponent implements OnInit {
  public aboutMemorialForm: FormGroup;
  public isSubmitted = false;
  public showMaxlength = false;
  public update = false;
  public tinyMceKey;
  memberId: string;
  action: string;
  updateHighlightCareer: any;
  updateHighlightFamily: any;
  updateHighlightMemories: any;
  constructor(public router: Router, public formBuilder: FormBuilder, public commonService: CommonService, 
    public toastr: ToastrService, public activatedRoute: ActivatedRoute,
    public sazitizer: DomSanitizer, public loader: NgxSpinnerService, public location: Location, public memberService: MemberService) {
    this.tinyMceKey = environment.tinyMceKey;
    this.aboutMemorialForm = this.formBuilder.group({
      aboutMemorial: ['', [Validators.required]]
    });
    window.scrollTo(0, 0);
  }

  ngOnInit(): void {
    if (localStorage.getItem('member_id')) {
      this.memberId = localStorage.getItem('member_id');
      this.update = true;
      this.getMemberData(this.memberId);
      this.getMemberDataHighlight(this.memberId);
    }
    this.activatedRoute.params.subscribe((params) => {
      if (params.id) {
        this.memberId = params.id;
        this.update = true;
        this.getMemberData(this.memberId);    
        this.getMemberDataHighlight(this.memberId);   
      }
    });
    window.scrollTo(0, 0);
    if (localStorage.getItem('update')) {
      this.update = true;
    }
    this.loader.show();
    setTimeout(() => {
      this.loader.hide();
    }, MagicNumbers.fifteenHundred);

  }
  get aboutMemorial() { return this.aboutMemorialForm.get('aboutMemorial'); }


  getMemberData(id) {
    this.memberService.getMemberStep(id, MagicNumbers.two).subscribe((res: IResponse) => {
      if (res && !res.errors) {
        let aboutMemorial = res.data;
        this.aboutMemorialForm.patchValue({
          aboutMemorial: aboutMemorial?.about_memorial == 'undefined' ? '': aboutMemorial?.about_memorial,
        }); 
      }
    });
  } 

  getMemberDataHighlight(id) {
    this.memberService.getMemberStep(id, MagicNumbers.five)
      .pipe(
        concatMap((res: IResponse) => {
          if (res && !res.errors) {
            const result = res.data;
            this.updateHighlightMemories = result.member_gallery.length !== 0;
          }
          // Return the next observable to continue in sequence
          return this.memberService.getMemberStep(id, MagicNumbers.four);
        }),
        concatMap((res: IResponse) => {
          if (res && !res.errors) {
            const result = res.data;
            this.updateHighlightFamily = result.about_family !== 'undefined';
          }
          // Return the next observable to continue in sequence
          return this.memberService.getMemberStep(id, MagicNumbers.three);
        })
      )
      .subscribe(
        (res: IResponse) => {
          if (res && !res.errors) {
            const result = res.data;
            this.updateHighlightCareer = result.career_accomplishments !== 'undefined';
          }
        },
        (error) => {
        }
      );
  }

  /**
     * To navigate to next step on submit
  */
  onSubmit(action:string) {
    const countWords = document.getElementById('memorial')?.innerText;
    this.isSubmitted = true;
    if (this.aboutMemorialForm.valid) {
      const countWordLen = countWords.trim().split(/\s+/).length;
      if (countWordLen > MagicNumbers.threeHundred) {
        this.showMaxlength = true;
      } else if (countWordLen <= MagicNumbers.threeHundred) {

        // if(this.update){
          this.memberService.updateMember2(this.memberId, MagicNumbers.two, { about_memorial: this.aboutMemorialForm.value.aboutMemorial }).subscribe((res: IResponse) => {
            if (res && !res.errors) {
              if(action == 'saveNext'){
                this.router.navigate(['/about-careers-hobbies', this.memberId]);
              } else{
                this.toastr.success('Details updated successfully.');
                this.router.navigate(['/view-information/',res.data.id]);
              }
            }
          });
      }
    } else {
      this.aboutMemorialForm.markAllAsTouched();
    }
  }
  /**
     * To navigate to previous step on back
  */
  goBack() {
    if (this.update) {
      this.router.navigate(['/update-info',this.memberId]);

    } else {
      this.router.navigate(['/add-loved-one']);
    }
  }
  /**
   * To listen editor changes event
   * @param e is required from editor
  */
  editorChanges(e) {
    this.showMaxlength = false;
  }
}
