import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UtilityService } from '../../common/services/utility.service';
import { IResponse } from '../../common/interfaces/response';
import { CommonService } from '../../common/services/common.service';
import { MemberService } from '../../common/services/member.service';
import { ExportSalesReportComponent } from '../../common/modal/export-sales-report/export-sales-report.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MarkAsReturnedComponent } from '../../common/modal/mark-as-returned/mark-as-returned.component';
import { LandingPageService } from '../../common/services/landing-page.service';
import { NoSubscriptionComponent } from '../../common/modal/no-subscription/no-subscription.component';
import { NoSubscriptionCancelledComponent } from '../../common/modal/no-subscription-cancelled/no-subscription-cancelled.component';
import { DowngradeAccountComponent } from '../../common/modal/downgrade-account/downgrade-account.component';
import { UpgradeAccountComponent } from '../../common/modal/upgrade-account/upgrade-account.component';
import { ToastrService } from 'ngx-toastr';
import { MemoryCodeRequiredComponent } from '../../public/component/memory-code-required/memory-code-required.component';
import { ManageCardComponent } from '../../common/modal/manage-card/manage-card.component';

@Component({
  selector: 'app-member-list',
  templateUrl: './member-list.component.html',
  styleUrls: ['./member-list.component.scss']
})
export class MemberListComponent implements OnInit,OnDestroy {
  public userData;
  public iconId:any;
  public type:any;
  public memberList;
  public destroy$ = new Subject();
  public showDropDown=false;
  public modelref: BsModalRef;
  public className = 'signin-modal';
  public classNameCard = 'signin-modal';
  cancelledSubs: any;
  public showIcon: any={
    pending_request:null
  };
  showAddLovedOne = false;
  showMemoryCode: boolean;

  constructor(
    public memberService: MemberService, 
    public commonService: CommonService, 
    public router: Router,
    public loaderService: NgxSpinnerService,
    public utilityService: UtilityService,
    public modalService: BsModalService,
    public landingPageService: LandingPageService,
    public commonStateService: CommonService,
    public toaster: ToastrService
    ) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.commonService.userData.pipe(takeUntil(this.destroy$)).subscribe(res => {
      if (res) {
        this.userData = res;
      }
    });
    if(JSON.parse(localStorage.getItem('userData'))){
      this.checkPendingReq(JSON.parse(localStorage.getItem('userData'))?.members[0]?.id);
    }
    this.getMemberList();
    this.checkAddMemberAndLovedOne();
  }

  ngAfterViewInit(): void {
    this.commonStateService.guestRequestSharing.subscribe((res)=>{
      this.showIcon = res;
    });
  }

  /**
   * API Call for members list
  */
  getMemberList() {
    this.loaderService.show();
    this.memberService.memberList().subscribe((res: IResponse) => {
      if (res && !res.errors) {
        this.memberList = res.data;
        const memberData = {
          member_id : res.data[0].id
        };
        localStorage.setItem('memberGuest',JSON.stringify(memberData));
        localStorage.setItem('member_id',JSON.stringify(res.data[0].id));
        localStorage.setItem('serial_number',JSON.stringify(res.data[0].user.serial_number));
        this.loaderService.hide();
      }
    });
  }

    openDowngradePlan(memberId: number) {
      this.getSubscriptionDowngrade(memberId);
    }
    getSubscriptionDowngrade(memberId: number) {
      this.landingPageService.getSubscriptionId(memberId).subscribe((res: IResponse) => {
        if (res && !res.errors) {
          this.cancelledSubs = res.data['status'];
          localStorage.setItem('cancelledSubs', JSON.stringify(this.cancelledSubs));
          
          // Now open the appropriate modal based on the subscription status
          const initialState = {
            memberId: memberId
          };
    
          if (this.cancelledSubs == '0') {
            this.modalService.show(NoSubscriptionComponent, { initialState, class: this.className });
          } else if (this.cancelledSubs == '3') {
            this.modalService.show(NoSubscriptionCancelledComponent, { initialState, class: this.className });
          } else {
            this.modalService.show(DowngradeAccountComponent, { initialState, class: this.className });
          }
        }
      });
    }

    openUpgradePlan(memberId: number) {
      this.getSubscriptionUpgrade(memberId);
    }
    
    getSubscriptionUpgrade(memberId: number) {
      this.landingPageService.getSubscriptionId(memberId).subscribe((res: IResponse) => {
        if (res && !res.errors) {
          this.cancelledSubs = res.data['status'];
          localStorage.setItem('cancelledSubs', JSON.stringify(this.cancelledSubs));
          
          // Now open the appropriate modal based on the subscription status
          const initialState = {
            memberId: memberId
          };
    
          if (this.cancelledSubs == '0') {
            this.modalService.show(NoSubscriptionComponent, { initialState, class: this.className });
          } else if (this.cancelledSubs == '3') {
            this.modalService.show(NoSubscriptionCancelledComponent, { initialState, class: this.className });
          } else {
            this.modalService.show(UpgradeAccountComponent, { initialState, class: this.className });
          }
        }
      });
    }
    
      openMemoryCode(id) {
        this.router.navigate(['/memory-code/',id]);
      }

      openManageRequests(id) {
        this.router.navigate(['/guest-request',id]);
      }
  /**
   * show Icon red method
  */
checkPendingReq(memberID){
    this.utilityService.checkPendingReq(memberID).subscribe((res: any) => {
      if (res && !res.errors) {
         this.commonService.guestRequestSharing.next(res.data);
      }
      });
  }
  

    /**
   * To navigate to the form when edit
   */
    updateDetails(id) {
      localStorage.setItem('update', 'true');
      localStorage.setItem('addMember', JSON.stringify(this.memberList));
      this.router.navigate(['/update-info/',id]);
    }
    
  /**
   * To navigate to member information details
   * @param data is from members list
  */
  viewMemberInfo(data) {
    this.router.navigate(['/view-information/',data.id]);
  }
   /**
     * To download the qr memory code 
    */
   async downloadCode(value) {
    fetch(value)
      .then(response =>
        response.blob()
      )
      .then(blob => {
        const blobUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.download = value.replace(/^.*[\\\/]/, '');
        a.href = blobUrl;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  }
    /**
   * To open the edit profile
  */
    export(memberId) {
      const initialState ={ memberId: memberId };
      this.modelref = this.modalService.show(ExportSalesReportComponent, { class: 'max-width-605 modal-dialog-centered', initialState});
    }

    openManageCard(memberId) {
      const initialState ={ memberId: memberId };
      this.modalService.show(ManageCardComponent, { initialState, class: this.classNameCard });
    }
  
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openReturnModal(memberId, serialNumber) {
    const initialState = {
      memberId: memberId,
      serialNumber : serialNumber
    };
    this.modalService.show(MarkAsReturnedComponent, { class: this.className, initialState });
  }

  goToGallery(id){
    const gallery ={
      active : 'gallery',
      locationUrl : '/members-list'
    };
    localStorage.setItem('gallerySection',JSON.stringify(gallery));
    this.router.navigate(['/guestbook-gallery', id]);
  }

  openMemoryCodeRequired() {
    this.modelref = this.modalService.show(MemoryCodeRequiredComponent,
      { animated: true, keyboard: true, backdrop: true, ignoreBackdropClick: false, class: this.className });
    this.modelref.content.onClose.subscribe((result) => {
      if (result.close) {

        this.landingPageService.checkMemoryCode(result.memoryCodeForm).subscribe((res: IResponse) => {
        if (res && !res.errors) {
          this.checkAddMemberAndLovedOne();
          const userData = localStorage.getItem('userData');
          const updatePlanMemberData = JSON.parse(userData);
          updatePlanMemberData.user.serial_number = result.memoryCodeForm;
          this.commonService.userData.next(updatePlanMemberData);
          localStorage.setItem('userData', JSON.stringify(updatePlanMemberData));
          this.showAddLovedOne = true;
          this.toaster.success(res.message);
        }
      });
      }
    });
  }

  addNewMember(){
    this.memberService.checkAddMemberLovedOne(true).subscribe((res: any) => {
      if (res && !res.errors) {
        if(res.data.status == false){
          this.router.navigate(['/upgrade-plans']);
        } else{
          this.toaster.error('','You have already bought a plan. Please proceed to add a beloved one.');
        }}});
  }

  checkAddMemberAndLovedOne(){
    this.memberService.checkAddMemberLovedOne(false).subscribe((res: any) => {
      if (res && !res.errors) {
        if(res.data.status == true && res.data.serial_number){
          this.showAddLovedOne = true;
        } else{
          this.showAddLovedOne = false;
        }

        if(res.data.status == true && !res.data.serial_number){
          this.showMemoryCode = true;
        } else{
          this.showMemoryCode = false;
        }
      }
      });
  }

  goToAddLovedOne(){
    localStorage.removeItem('member_id');
    localStorage.removeItem('update');
    this.router.navigate(['/add-loved-one']);
  }
}
