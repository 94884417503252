import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LandingPageService {
 public apiUrl: any;

  constructor(public http: HttpClient) {
    this.apiUrl = environment.apiUrl;

  }
  checkMemoryCode(memoryCode: string) {
    return this.http.post(`${environment.apiUrl}save-qr-code?serial_number=${memoryCode}`, '');
  }
  getContentBasedonPage(page: string) {
    return this.http.get(`${environment.apiUrl}get_static_content?page=${page}`);

  }
  getFAQ(params){
    return this.http.get(`${environment.apiUrl}frontend-faq-list?${params}`);
  }

  getAdData(){
    return this.http.get(`${environment.apiUrl}get-ad-spaces`);
  }

  getSubscriptionId(member_id){
    return this.http.get(`${environment.apiUrl}get-subscription-id?member_id=${member_id}`);
  }

  getSavedCard(member_id){
    return this.http.post(`${environment.apiUrl}get-saved-card?member_id=${member_id}`,'');
  }

  getCoOwnersList(params){
    return this.http.get(`${environment.apiUrl}co-owner-list?${params}`);
  }

  adClick(adId) {
    return this.http.post(`${environment.apiUrl}store-click-event/${adId}`, '');
  }
  getStates() {
    return this.http.get(`${this.apiUrl}common/states`);
  }

}
