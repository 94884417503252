import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { MemoryCodeRequiredComponent } from '../../../public/component/memory-code-required/memory-code-required.component';
import { SigninComponent } from '../../../public/component/signin/signin.component';
import { SignUpComponent } from '../../../public/component/sign-up/sign-up.component';
import { IResponse } from '../../interfaces/response';
import { ChangePasswordComponent } from '../../modal/change-password/change-password.component';
import { EditProfileComponent } from '../../modal/edit-profile/edit-profile.component';
import { SearchComponent } from '../../modal/search/search.component';
import { AuthService } from '../../services/auth.service';
import { CommonService } from '../../services/common.service';
import { MagicNumbers } from '../app-magic-numbers';
import { Location } from '@angular/common';
import { ManageCoOwnersComponent } from '../../modal/manage-co-owners/manage-co-owners.component';
import { MemberService } from '../../services/member.service';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit,AfterViewInit,OnDestroy {
  public destroy$ = new Subject();
  public showBrandLogo = true;
  public isLoggedIn = false;
  public showNav = false;
  public userData;
  public className = 'manage-co-owner-modal';
  public overlayClass = 'overlay-hidden';
  public showIcon: any={
    pending_request:null
  };
  standardPlan = true;
  guestUserEmail: any;
  showSub = false;
  planCancelled = false;
  memberList;
  memberListData;
  guestUser: any;
  newPrivateMessage: any;
  currentRoute:string;
  constructor(private readonly modalService: BsModalService, public router: Router, public commonStateService: CommonService, 
    public memberService: MemberService, public loaderService: NgxSpinnerService,
    public authService: AuthService, public toastr: ToastrService,
    public location: Location) {
    this.commonStateService.userData.pipe(takeUntil(this.destroy$)).subscribe(res => {
      if (res) {
        this.userData = res;
        this.isLoggedIn = true;
      if(this.userData?.user?.plan_type === 1){
        this.standardPlan = true;
      } else if(this.userData?.user?.plan_type === MagicNumbers.two){
        this.standardPlan = false;
      }
      if(this.userData.user.member !== null){
      }
      } else {
        this.isLoggedIn = false;
      }
      if(this.userData?.user?.subscription_status!=null){
        this.showSub = true;
      } else{
        this.showSub = false;
      }
    });
  }

  ngAfterViewInit(): void {
    this.commonStateService.guestRequestSharing.subscribe((res)=>{
      this.showIcon = res;
    });
   this.guestUserEmail =  localStorage.getItem('memberGuestEmail');
    this.guestUser = JSON.parse(localStorage.getItem('memberGuest'));
    if(this.guestUser.guestbook_id){
      const data = {
        member_id: this.guestUser.member_id,
        guestbook_id: this.guestUser.guestbook_id || null
      };
      this.loaderService.show();
      this.memberService.fetchNewMessageGuestUser(data).subscribe(response => {
        // Handle the response from the API
        this.newPrivateMessage = response;
        this.loaderService.hide();
      }, error => {
        this.loaderService.hide();
      });
    }
  }

  ngOnInit(): void {
    // Checking the brandicon based on window size
    this.commonStateService.changeBrandIcon.pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.showBrandLogo = res;
      if (window.innerWidth <= MagicNumbers.innerWidth) {
        this.showBrandLogo = false;
      }
    });
    this.commonStateService.memberGuestEmail.subscribe((res)=>{
      this.guestUserEmail = res;
    });

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.currentRoute = event.urlAfterRedirects;
    });
  };

  plantTree(){
    const url = 'https://evertreen.com/company/eternallyme';
    window.open(url, '_blank');
  }

  /**
   * Opening the Sign-in modal
  */
  openSignIn() {
    this.modalService.show(SigninComponent, { class: this.className });
  }

  openSignUp() {
    this.modalService.show(SignUpComponent, { class: this.className });
  }
  /**
     * Opening the Memory code modal
    */
  openMemoryCodeRequired() {
    this.modalService.show(MemoryCodeRequiredComponent, { class: this.className });
  }

  /**
   * Opening the upgrade plan modal
  */

  manageCoOwners() {
     this.modalService.show(ManageCoOwnersComponent, { class: this.className });
    }

  /**
   * API Call for Logout
  */
  logout() {
    this.authService.logout().subscribe((res: IResponse) => {
      if (res && !res.errors) {
        this.commonStateService.userData.next(null);
        localStorage.clear();
        this.toastr.success('Logged out successfully');
        this.router.navigate(['/landing-page']);
        this.commonStateService.guestRequestSharing.next('');
      }
    });
  }

  goToRequests(){
    if(this.userData.members){
    this.router.navigate(['/guest-request']);
    } else{
      this.toastr.error('Please add member to view requests');
    }
  }
  /**
   * Opening the change password
  */
  openChangePassword() {
    this.modalService.show(ChangePasswordComponent, { class: this.className });
  }

  /**
   *  Open the edit profile
  */
  openEditProfile() {
    this.modalService.show(EditProfileComponent, { class: this.className });
  }

  /**
   * Toggleling the nav in mobile
  */
  navToggle() {
    this.showNav = !this.showNav;
    if (this.showNav) {
      document.getElementById(this.overlayClass)?.classList.add('scroll-remove');
      document.getElementById(this.overlayClass)?.classList.add('scroll-stop');

    }
  }

  showNavBar(){
    this.showNav =false;
    document.getElementById(this.overlayClass)?.classList.remove('scroll-stop');
  }
  onClose() {
    document.getElementsByClassName('mat-typography')[0].setAttribute('class', 'mat-typography');

  }
  /**
   * Navigating to dashboard based on user
  */
  goDashboard() {
    this.memberService.memberList().subscribe((res: IResponse) => {
      if (res && !res.errors) {
        this.memberList = res.data;
        if(this.memberList.length != 0){
          this.memberListData = true;
        } else{
          this.memberListData = false;
        }
      }
    const userData = localStorage.getItem('userData');
    const userMemberData = JSON.parse(userData);
    if(this.router.url.split('/')[1] == 'upgrade-plans' && userMemberData.user.account_type == '1' && this.memberListData == false){
      this.router.navigate(['/upgrade-plans']);
      return;
    }  
    if(this.isLoggedIn && !(userMemberData?.user?.plan_type) && userMemberData.user.account_type == '1' && this.memberListData == false){
      this.router.navigate(['/upgrade-plans']);
    } else if (this.isLoggedIn && this.memberListData == true) {
      this.router.navigate(['/members-list']);
    } else if (this.isLoggedIn && this.memberListData == false) {
      this.router.navigate(['/home']);
    } else {
      this.router.navigate(['/']);
    }
  });
  }

  getMemberList() {
    this.loaderService.show();
    this.memberService.memberList().subscribe((res: IResponse) => {
      if (res && !res.errors) {
        this.memberList = res.data;
        this.loaderService.hide();
      }
    });
  }

  /**
   * Open the search modal
  */
  openSearch() {
    this.modalService.show(SearchComponent, { class: 'search-modal-dialog' });
  }

  routeUrl(){
    const url = localStorage.getItem('viewMemberUrl');
    this.router.navigateByUrl(url);
  }

  goToChatFromGuest(){
    this.router.navigate(['/send-message-guest']);
  }

  goToSendRequest(){
    this.router.navigate(['/add-guest-message']);
  }

  /**
   * Navigate to about us
  */
  goToAboutUs() {
    this.showNav = false;
    window.open(location.origin + '#/about-us', '_blank');
  }
  goToFAQ() {
    this.showNav = false;
    window.open(location.origin + '#/faq', '_blank');
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
