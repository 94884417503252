import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { PaginationResponse } from '../interfaces/paginationResponse';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MemberService {
  public apiUrl;
  constructor(public http: HttpClient) {
    this.apiUrl = environment.apiUrl;
  }
  addMember(step, data) {
    const fd = new FormData();
    fd.append('first_name', data.first_name);
    fd.append('last_name', data.last_name);
    fd.append('dob', data.dob);
    fd.append('dod', data.dod);
    fd.append('state_id', data.state_id);
    fd.append('city_id', data.city_id);
    fd.append('gender', data.gender);
    fd.append('cemetery_name', data.cemetery_name);
    fd.append('plot_number', data.plot_number);
    fd.append('row_number', data.row_number);
    fd.append('section', data.section);
    fd.append('cemetery_address', data.cemetery_address);
    fd.append('zip_code', data.zip_code);
    fd.append('burial_state_id', data.burial_state_id);
    fd.append('burial_city_id', data.burial_city_id);
    fd.append('about_family', data.about_family);
    fd.append('favorite_memories', data.favorite_memories);
    fd.append('hobbies', data.hobbies);
    fd.append('career_accomplishments', data.career_accomplishments);
    fd.append('about_memorial', data.about_memorial);
    fd.append('profile_picture', data.profile_picture);
    fd.append('other_picture', data.other_picture);
    if(data.prayer_card){
      fd.append('prayer_card', data.prayer_card);
    }
    fd.append('is_burial_private', (data.is_burial_private));
    fd.append('is_dob_private', (data.is_dob_private));
    fd.append('frame_no', data.frame_no);
    fd.append('video', data.video ? data.video : JSON.stringify([]));
    fd.append('cemetery_website', data.cemetery_website);
    if (data.middle_name) {
      fd.append('middle_name', data.middle_name);
    }
    return this.http.post(`${this.apiUrl}member-create/step/${step}`, fd);
  }

  getMemberStep(id, step){
    return this.http.get(`${this.apiUrl}get-member/${id}/step/${step}`);
  }

  updateMember(id, step, data) {
    const fd = new FormData();
    fd.append('dob', data.dob);
    fd.append('dod', data.dod);
    fd.append('about_family', data.about_family);
    fd.append('favorite_memories', data.favorite_memories);
    fd.append('hobbies', data.hobbies);
    fd.append('career_accomplishments', data.career_accomplishments);
    fd.append('about_memorial', data.about_memorial);
    fd.append('profile_picture', data.profile_picture);
    fd.append('other_picture', data.other_picture);
    if(data.prayer_card){
      fd.append('prayer_card', data.prayer_card);
    }
    fd.append('is_burial_private', (data.is_burial_private));
    fd.append('is_dob_private', (data.is_dob_private));
    fd.append('frame_no', data.frame_no);
    fd.append('video', data.video ? data.video : JSON.stringify([]));
    return this.http.post(`${this.apiUrl}member-edit/${id}/step/${step}`, fd);
  }

  updateMember2(id, step, data) {
    const fd = new FormData();
    fd.append('about_memorial', data.about_memorial);
    return this.http.post(`${this.apiUrl}member-edit/${id}/step/${step}`, fd);
  }

  updateMember3(id, step, data) {
    const fd = new FormData();
    fd.append('hobbies', data.hobbies);
    fd.append('career_accomplishments', data.career_accomplishments);    
    return this.http.post(`${this.apiUrl}member-edit/${id}/step/${step}`, fd);
  }

  updateMember4(id, step, data) {
    const fd = new FormData();
    fd.append('about_family', data.about_family);
    fd.append('favorite_memories', data.favorite_memories);  
    return this.http.post(`${this.apiUrl}member-edit/${id}/step/${step}`, fd);
  }

  updateMember5(id, step, data) {
    const fd = new FormData();
    fd.append('other_picture', data.other_picture);
    if(data.prayer_card){
      fd.append('prayer_card', data.prayer_card);
    }
    fd.append('video', data.video ? data.video : JSON.stringify([]));
    return this.http.post(`${this.apiUrl}member-edit/${id}/step/${step}`, fd);
  }
  downloadQr(memberId) {
    return this.http.post(`${this.apiUrl}download-qr?id=${memberId}`, '');
  }
  memberList() {
    return this.http.post(`${this.apiUrl}member-list`, '');
  }
  getMessageList(payloads) {
    return this.http.get(`${this.apiUrl}guestbook/message-list?member_id=${payloads.member_id}&source=${payloads.source}&page=${payloads.page}`);
  }
    /**
  * API Call for add media in guestbook
  * @payloads means data having media values
  */
  getMediaList(payloads: any) {
    return this.http.get(`${this.apiUrl}guestbook/media-list?member_id=${payloads.member_id}&type=${payloads.type}&source=${payloads.source}&page=${payloads.page}`);
  }
  
  getBroadCastMessageList(payloads) {
    return this.http.get(`${this.apiUrl}broadcast/message-list?member_id=${payloads.member_id}&page=${payloads.page}`);
    }

  /**
  * API Call for add media in guestbook
  * @payloads means data having media values
  */
  getAllowMediaLimit(payloads?: any,type?) {
   if(type){
     return this.http.get(`${this.apiUrl}guestbook/allowed-media-limit?member_id=${payloads.member_id}&type=${type}`);
   }else{
     return this.http.get(`${this.apiUrl}guestbook/allowed-media-limit?member_id=${payloads.member_id}`);
   }
  }

    /**
  * API Call for add media in guestbook
  * @payloads means data having media values
  */
  getAllowMediaLimitMember(payloads?:any) {
      return this.http.get(`${this.apiUrl}guestbook/allowed-media-limit?member_id=${payloads.member_id}&type=${payloads.type}`);
    }
  
    getReturn() {
      return this.http.get(`${environment.apiUrl}common/return-reasons`);
    }

    markAsReturn(params) {
      return this.http.post(`${environment.apiUrl}return-qr/${params}`, '');
    }

    getObituaryId(id) {
      return this.http.get(`${this.apiUrl}share-obituary?member_id=${id}`);
    }

    getObituaryIdUrl(id) {
      return this.http.get(`${this.apiUrl}share-obituary?member_id=${id}`);
    }

    getObituary(id) {
      return this.http.get(`${this.apiUrl}obituary?member_id=${id}`);
      }

      postObituary(obt) {
        return this.http.post(`${this.apiUrl}obituary?obituary=${obt}`, '');
      }

      deleteObituary() {
        return this.http.delete(`${this.apiUrl}obituary`);
      }

      checkLovedOne(){
        return this.http.get(`${environment.apiUrl}check_deceased_availability`);
      }

      checkAddMemberLovedOne(status) {
        return this.http.get(`${this.apiUrl}check_deceased_availability?check_member=${status}`);
      }

      checkUserDetails(id){
        return this.http.get(`${environment.apiUrl}get-user-details?id=${id}`);
      }

      addItenerary(payloads) {
        const URL = this.apiUrl + 'itinerary-card-create';
        return this.http.post(URL, payloads);
      }
      validateShippoAddress(payloads) {
        const URL = this.apiUrl + 'validate-address';
        return this.http.post(URL, payloads);
      }

      getItineraryCard(id){
        return this.http.get(`${environment.apiUrl}get-itinerary-card/${id}`);
      }

      getMessageListForOwner(id){
        return this.http.get(`${environment.apiUrl}privateinbox/guest-user-list?member_id=${id}`);
      }
      fetchNewMessageGuestUser(payloads){
        return this.http.get(`${environment.apiUrl}privateinbox/new-message?member_id=${payloads.member_id}&guestbook_id=${payloads.guestbook_id}`);
      }

      fetchChatList(member_id: number, user_id?: number, guestbook_id?: number): Observable<any> {
        let params = new HttpParams().set('member_id', member_id.toString());
    
        if (user_id) {
          params = params.set('user_id', user_id.toString());
        }
    
        if (guestbook_id) {
          params = params.set('guestbook_id', guestbook_id.toString());
        }
    
        return this.http.get(`${environment.apiUrl}privateinbox/message-list`, { params });
      }

        sendMessage(data: any): Observable<any> {
          return this.http.post(`${environment.apiUrl}privateinbox/send-message`, data);
        }
  
}
